/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { Text } from '@traefiklabs/faency'
import React, { useMemo } from 'react'

import { errCodesToMessage } from 'helpers/errCodes'

type ErrorMessageProps = {
  errCode?: number
  errText?: string
}

export const ErrorMessage = ({ errCode, errText }: ErrorMessageProps) => {
  const message = useMemo(() => {
    if (errText) return errText
    const defaultMessage = errCodesToMessage[500]
    if (!errCode) return defaultMessage
    return errCodesToMessage[errCode] || defaultMessage
  }, [errCode, errText])

  return (
    <Text size={3} variant="red" css={{ lineHeight: '24px', textAlign: 'center' }}>
      {message}
    </Text>
  )
}

export default ErrorMessage
