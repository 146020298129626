/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { Card, Flex } from '@traefiklabs/faency'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

type AuthLayoutProps = {
  children: ReactNode
  title?: string
}

export const AuthLayout = ({ children, title }: AuthLayoutProps) => (
  <>
    <Helmet>
      <title>{title || 'API Portal'}</title>
    </Helmet>
    <Flex align="center" css={{ width: '100%', height: '100vh' }}>
      <Card css={{ flexGrow: 1, margin: '0 auto', maxWidth: '408px', p: '$6' }}>{children}</Card>
    </Flex>
  </>
)

export default AuthLayout
