/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { Alert, Box, Button, Flex, H1, Label, Text, TextField } from '@traefiklabs/faency'
import axios from 'axios'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React, { useMemo, useState } from 'react'

import * as Yup from 'yup'

import AuthLayout from 'components/layouts/AuthLayout'
import ErrorMessage from 'components/ErrorMessage'

interface ResetPasswordForm {
  password: string
  confirmPassword: string
  resetCode?: string
  userId?: string
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

type ResetPasswordProps = {
  errCode?: string
  resetCode?: string
  userId?: string
}

export const ResetPassword = ({ errCode, resetCode, userId }: ResetPasswordProps) => {
  const initialValues = useMemo<ResetPasswordForm>(
    () => ({
      password: '',
      confirmPassword: '',
      resetCode,
      userId,
    }),
    [resetCode, userId],
  )

  const [errText, setErrText] = useState<string | undefined>()
  const [mergedErrCode, setMergedErrCode] = useState<number | undefined>(errCode ? +errCode : undefined)
  const [pageStatus, setPageStatus] = useState<'init' | 'success'>('init')

  const onSubmit = async (values: ResetPasswordForm, actions: FormikHelpers<ResetPasswordForm>) => {
    try {
      setErrText(undefined)
      setMergedErrCode(errCode ? +errCode : undefined)
      await axios.post('/reset-password', {
        userId: values.userId,
        resetCode: values.resetCode,
        password: values.password,
      })
      setPageStatus('success')
    } catch (err: any) {
      console.error(err)
      if (err.response) {
        if (err.response.status) {
          if (err.response.status === 422) {
            setErrText(err.response.data.error)
          }
          setMergedErrCode(err.response.status)
        } else {
          setMergedErrCode(500)
        }
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <AuthLayout>
      <Flex direction="column">
        <Flex align="center" direction="column" css={{ mb: pageStatus === 'init' ? '$6' : 0 }}>
          <H1 css={{ color: '$blackA12', fontSize: '$9', fontWeight: 'bold', mb: '$2' }}>Reset password</H1>
          <Text size="3" css={{ color: '$blackA11', lineHeight: '24px' }}>
            {pageStatus === 'init'
              ? 'Welcome back! Please enter your new password.'
              : 'Your password has been updated successfully.'}
          </Text>
        </Flex>
        {pageStatus === 'init' && (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ dirty, isSubmitting }) => (
              <Form>
                <Box css={{ mb: '$5' }}>
                  <Label htmlFor="password">
                    <Text size="1" css={{ color: '$blackA11', fontWeight: '600', lineHeight: '18px' }}>
                      Password
                    </Text>
                  </Label>
                  <Field name="password">
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          type="password"
                          autoComplete="new-password"
                          placeholder="Enter your new password"
                          required
                          size="large"
                        />
                        {meta.touched && typeof meta.error === 'string' && (
                          <Text size={1} variant="red" css={{ fontWeight: '600', pt: '$2' }}>
                            {meta.error}
                          </Text>
                        )}
                      </>
                    )}
                  </Field>
                </Box>
                <Box css={{ mb: '$5' }}>
                  <Label htmlFor="confirmPassword">
                    <Text size="1" css={{ color: '$blackA11', fontWeight: '600', lineHeight: '18px' }}>
                      Confirm password
                    </Text>
                  </Label>
                  <Field name="confirmPassword">
                    {({ field, meta }) => (
                      <>
                        <TextField
                          {...field}
                          type="password"
                          autoComplete="new-password"
                          placeholder="Confirm your new password"
                          required
                          size="large"
                        />
                        {meta.touched && typeof meta.error === 'string' && (
                          <Text size={1} variant="red" css={{ fontWeight: '600', pt: '$2' }}>
                            {meta.error}
                          </Text>
                        )}
                      </>
                    )}
                  </Field>
                </Box>
                {!!mergedErrCode && (
                  <Box css={{ mb: '$5' }}>
                    <Alert variant="error">
                      <ErrorMessage errCode={mergedErrCode} errText={errText} />
                    </Alert>
                  </Box>
                )}
                <Flex>
                  <Button
                    as="input"
                    type="submit"
                    value="Reset password"
                    disabled={!dirty}
                    state={isSubmitting ? 'waiting' : undefined}
                    size="large"
                    css={{ flexGrow: 1, textAlign: 'center' }}
                  />
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Flex>
    </AuthLayout>
  )
}

export default ResetPassword
