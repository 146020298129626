/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { FaencyProvider, globalCss, lightTheme } from '@traefiklabs/faency'
import React, { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom'

import Error from './pages/Error'
import LogIn from './pages/LogIn'
import ResetPassword from './pages/ResetPassword'
import { getInjectedValues } from './utils/getInjectedValues'

const queryClient = new QueryClient()

const light = lightTheme('blue')

const bodyGlobalStyle = globalCss({
  body: {
    backgroundColor: '$gray2',
    boxSizing: 'border-box',
    margin: 0,
  },
})

const { portalTitle, portalLogoUrl, loginChallenge, workspaceId, errCode, resetCode, userId } = getInjectedValues()

const Routes = () => {
  return (
    <RouterRoutes>
      {bodyGlobalStyle()}
      <Route
        path="/login"
        element={
          <LogIn
            errCode={errCode}
            loginChallenge={loginChallenge}
            portalLogoUrl={portalLogoUrl}
            portalTitle={portalTitle}
            workspaceId={workspaceId}
          />
        }
      />
      <Route path="/error" element={<Error errCode={errCode} />} />
      <Route
        path="/reset-password"
        element={<ResetPassword errCode={errCode} resetCode={resetCode} userId={userId} />}
      />
    </RouterRoutes>
  )
}

const App = () => {
  useEffect(() => {
    document.body.classList.add(light.toString())
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <FaencyProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </FaencyProvider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App
