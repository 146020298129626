/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

interface InjectedValues {
  portalName?: string
  portalTitle?: string
  portalDescription?: string
  portalLogoUrl?: string

  loginChallenge?: string
  workspaceId?: string

  errCode?: string

  resetCode?: string
  userId?: string
}

export const getInjectedValues = (): InjectedValues => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {
    portalName,
    portalTitle,
    portalDescription,
    portalLogoUrl,
    loginChallenge,
    workspaceId,
    errCode,
    resetCode,
    userId,
  } = window as any

  return {
    portalName,
    portalTitle,
    portalDescription,
    portalLogoUrl,
    loginChallenge,
    workspaceId,
    errCode,
    resetCode,
    userId,
  }
}
