/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { Flex, H1 } from '@traefiklabs/faency'
import React from 'react'

import AuthLayout from 'components/layouts/AuthLayout'
import ErrorMessage from 'components/ErrorMessage'

type ErrorProps = {
  errCode?: string
}

export const Error = ({ errCode }: ErrorProps) => {
  return (
    <AuthLayout>
      <Flex direction="column">
        <Flex align="center" direction="column">
          <H1 css={{ color: '$blackA12', fontSize: '$9', fontWeight: 'bold', mb: '$2' }}>Something went wrong</H1>
          {!!errCode && <ErrorMessage errCode={+errCode} />}
        </Flex>
      </Flex>
    </AuthLayout>
  )
}

export default Error
