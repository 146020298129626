/*
Copyright (C) 2022-2023 Traefik Labs
This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.
This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.
You should have received a copy of the GNU Affero General Public License
along with this program. If not, see <https://www.gnu.org/licenses/>.
*/

import { Alert, Image, Box, Button, Flex, H1, H2, Label, Text, TextField } from '@traefiklabs/faency'
import { Field, Form, Formik } from 'formik'
import React, { useMemo, useRef } from 'react'
import * as Yup from 'yup'

import AuthLayout from 'components/layouts/AuthLayout'
import ErrorMessage from 'components/ErrorMessage'

interface LogInForm {
  email: string
  password: string
  loginChallenge?: string
  workspaceId?: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email('Must be a valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

type LogInProps = {
  errCode?: string
  loginChallenge?: string
  portalLogoUrl?: string
  portalTitle?: string
  workspaceId?: string
}

export const LogIn = ({ errCode, loginChallenge, portalLogoUrl, portalTitle, workspaceId }: LogInProps) => {
  const initialValues = useMemo<LogInForm>(
    () => ({
      email: '',
      password: '',
      loginChallenge,
      workspaceId,
    }),
    [loginChallenge, workspaceId],
  )

  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = () => {
    formRef.current?.submit()
  }

  return (
    <AuthLayout>
      <Flex direction="column">
        {!!portalTitle && (
          <Flex align="center" justify="center" gap={2} css={{ mb: '$6' }}>
            {!!portalLogoUrl && (
              <Image
                src={portalLogoUrl}
                alt="logo"
                css={{
                  border: '1px solid $grayA6',
                  borderRadius: '$2',
                  boxSizing: 'border-box',
                  height: '$7',
                  width: '$7',
                  objectFit: 'contain',
                }}
              />
            )}
            <H2 css={{ color: '$blackA12', fontSize: '$3', fontWeight: 'bold', lineHeight: '16px' }}>{portalTitle}</H2>
          </Flex>
        )}
        <Flex align="center" direction="column" css={{ mb: '$6' }}>
          <H1 css={{ color: '$blackA12', fontSize: '$9', fontWeight: 'bold', mb: '$2' }}>Log in to your API Portal</H1>
          <Text size="3" css={{ color: '$blackA11', lineHeight: '24px' }}>
            Welcome back! Please enter your details.
          </Text>
        </Flex>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ dirty }) => (
            <Form method="post" action="/login" ref={formRef}>
              <Field type="hidden" name="loginChallenge" />
              <Field type="hidden" name="workspaceId" />
              <Box css={{ mb: '$5' }}>
                <Label htmlFor="email">
                  <Text size="1" css={{ color: '$blackA11', fontWeight: '600', lineHeight: '18px' }}>
                    Email
                  </Text>
                </Label>
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <TextField
                        {...field}
                        type="text"
                        autoComplete="username"
                        placeholder="Enter your email"
                        required
                        size="large"
                      />
                      {meta.touched && typeof meta.error === 'string' && (
                        <Text size={1} variant="red" css={{ fontWeight: '600', pt: '$2' }}>
                          {meta.error}
                        </Text>
                      )}
                    </>
                  )}
                </Field>
              </Box>
              <Box css={{ mb: '$5' }}>
                <Label htmlFor="password">
                  <Text size="1" css={{ color: '$blackA11', fontWeight: '600', lineHeight: '18px' }}>
                    Password
                  </Text>
                </Label>
                <Field name="password">
                  {({ field, meta }) => (
                    <>
                      <TextField
                        {...field}
                        type="password"
                        autoComplete="current-password"
                        placeholder="Enter your password"
                        required
                        size="large"
                      />
                      {meta.touched && typeof meta.error === 'string' && (
                        <Text size={1} variant="red" css={{ fontWeight: '600', pt: '$2' }}>
                          {meta.error}
                        </Text>
                      )}
                    </>
                  )}
                </Field>
              </Box>
              {!!errCode && (
                <Box css={{ mb: '$5' }}>
                  <Alert variant="error">
                    <ErrorMessage errCode={+errCode} />
                  </Alert>
                </Box>
              )}
              <Flex>
                <Button
                  as="input"
                  type="submit"
                  value="Log in"
                  disabled={!dirty}
                  size="large"
                  css={{ flexGrow: 1, textAlign: 'center' }}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </AuthLayout>
  )
}

export default LogIn
